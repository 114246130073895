export class BlogSummaryDto {
  guid: string;
  publishDate: Date;
  title: string;
  thumbUrl?: string;

  constructor(
    guid: string,
    publishDate: Date,
    title: string,
    thumbUrl: string
  ) {
    this.guid = guid;
    this.publishDate = publishDate;
    this.title = title;
    this.thumbUrl = thumbUrl;
  }
}
