import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueGtag from "vue-gtag";
import { store, key } from "./store";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faHome,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";

library.add(faHome, faChevronDown, faChevronUp);

async function init() {
  const app = createApp(App);
  app.component("font-awesome-icon", FontAwesomeIcon);
  app
    .use(router)
    .use(store, key)
    .use(
      VueGtag,
      {
        config: { id: "G-DMH3QLRRFF" },
      },
      router
    )
    .mount("#app");
}

init();
