import { BlogSummaryDto } from "../models/BlogSummaryDto";
import { BlogEntryDto } from "../models/BlogEntryDto";
import { SpiritMessage, Message } from "@/models";

export default class Stub {
  public static async wisdoms(): Promise<Message[]> {
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve([
          new Message("Rakkaus", [
            "on kaiken ydin.",
            "Se on kaikki. Se on kaikkialla, kunhan huomaat.",
            "Rakkautta on joka hetkessä, ympärilläsi. Eri muodoissa.",
            "Avaa silmäsi ja katseesi. Pysähdy ja näe Rakkaus",
          ]),
          new Message("Usko...", [
            "...Johonkin aina uskot.",
            "Mutta valitse se oikea tie. Se joka tuo elämääsi onnea.",
            "Usko puhtaisiin yksinkertaisiin asioihin...",
            "esimerkiksi sydämeesi...",
            "kuunnellen mitä hänellä on asiaa....",
            "-Rakkaus-...",
          ]),
        ]);
      }, 500)
    );
  }
  public static async angelMessage(): Promise<Message> {
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve(
          new Message("Rakkaus", [
            "on kaiken ydin.",
            "Se on kaikki. Se on kaikkialla, kunhan huomaat.",
            "Rakkautta on joka hetkessä, ympärilläsi. Eri muodoissa.",
            "Avaa silmäsi ja katseesi. Pysähdy ja näe Rakkaus",
          ])
        );
      }, 500)
    );
  }
  public static async unicornMessage(): Promise<Message> {
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve(new Message("Viesti", ["abcdefg"]));
      }, 500)
    );
  }
  public static async blogEntries(): Promise<BlogSummaryDto[]> {
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve([
          new BlogSummaryDto(
            "c560ae42-6800-41af-942e-fa8e9a2fc3e4",
            new Date("2021-1-10"),
            "Ut enim ad minima veniam",
            "teddy.jpg"
          ),
          new BlogSummaryDto(
            "eca0d0a2-acdd-4dd0-935e-18198e218cd1",
            new Date("2021-2-6"),
            "Excepteur sint occaecat cupidatat non proident",
            "teddy.jpg"
          ),
          new BlogSummaryDto(
            "afc28730-af29-4f35-89d5-b53cac30d70c",
            new Date("2021-3-5"),
            "Sed fringilla mauris sit amet nibh",
            "teddy.jpg"
          ),
        ]);
      }, 500)
    );
  }
  public static async blogEntry(): Promise<BlogEntryDto> {
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve(
          new BlogEntryDto(
            new Date("2021-1-10"),
            "Ut enim ad minima veniam",
            "<img src='https://enkelinesiliina.fi:8080/images/wide/6635c6ff-b35f-45e7-a120-ba484c2c872c.jpg'/><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p>",
            null,
            null,
            "eca0d0a2-acdd-4dd0-935e-18198e218cd1",
            "Excepteur sint occaecat cupidatat non proident"
          )
        );
      }, 500)
    );
  }
  public static async getChapters(): Promise<string[]> {
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve(["Kappale1", "Kappale2", "Kappale2"]);
      }, 500)
    );
  }
  public static async getChapter(): Promise<Message> {
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve(
          new Message("Tootoo", [
            "consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
            "<poem>Kevät tuli",
            "lumiukko suli</poem>",
          ])
        );
      }, 500)
    );
  }
  public static async spiritMessageEntries(): Promise<SpiritMessage[]> {
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve([
          new SpiritMessage(
            "Syksyn satoa",
            [
              "consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
            ],
            "0"
          ),
          new SpiritMessage(
            "Erakon tarina",
            [
              "Phasellus a lectus molestie, tempor nulla eu, luctus eros. Nulla et risus eget est porttitor vulputate in et magna.",
            ],
            "1"
          ),
          new SpiritMessage(
            "Katuva henki",
            [
              "Cras tincidunt venenatis tincidunt. Pellentesque pellentesque sed tellus nec euismod.",
            ],
            "2"
          ),
        ]);
      }, 200)
    );
  }
  public static async spiritMessageEntry(): Promise<Message> {
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve(
          new Message("Syksyn satoa", [
            "Mauris vestibulum eget arcu eu vestibulum. Nulla commodo neque nec condimentum gravida. Integer vitae volutpat neque. Nulla tempus ex quis mollis consectetur. In odio odio, ullamcorper ac placerat sed, rhoncus sit amet erat. Pellentesque laoreet dui at viverra vestibulum. Nullam sem nisi, lobortis in ligula sed, semper condimentum felis. Vivamus facilisis aliquet nisl. Curabitur euismod dignissim ante. Nullam at felis vitae augue laoreet rutrum. \n Mauris vestibulum eget arcu eu vestibulum. Nulla commodo neque nec condimentum gravida. Integer vitae volutpat neque. Nulla tempus ex quis mollis consectetur. In odio odio, ullamcorper ac placerat sed, rhoncus sit amet erat. Pellentesque laoreet dui at viverra vestibulum. Nullam sem nisi, lobortis in ligula sed, semper condimentum felis. Vivamus facilisis aliquet nisl. Curabitur euismod dignissim ante. Nullam at felis vitae augue laoreet rutrum.",
          ])
        );
      }, 200)
    );
  }
}
