import { InjectionKey } from "vue";
import {
  createStore,
  useStore as baseUseStore,
  Store,
  GetterTree,
  MutationTree,
  ActionTree,
} from "vuex";

/* eslint-disable @typescript-eslint/no-explicit-any */

class State {}

const getters = <GetterTree<State, any>>{};

// state modifications
const mutations = <MutationTree<State>>{};

// async functions that mutate state
const actions = <ActionTree<State, any>>{};

export const store = createStore<State>({
  state: new State(),
  getters: getters,
  mutations: mutations,
  actions: actions,
  modules: {},
});

// define injection key
export const key: InjectionKey<Store<State>> = Symbol();

// define your own `useStore` composition function
export function useStore(): Store<State> {
  return baseUseStore(key);
}
