export class BlogEntryDto {
  publishDate?: Date;
  title?: string;
  html?: string;
  prevEntry?: string;
  prevIndex?: string;
  nextEntry?: string;
  nextIndex?: string;

  constructor(
    publishDate: Date,
    title: string,
    html: string,
    prevIndex: string | null,
    prevEntry: string | null,
    nextIndex: string | null,
    nextEntry: string | null
  ) {
    this.publishDate = publishDate;
    this.title = title;
    this.html = html;
    this.prevEntry = prevEntry ? prevEntry : undefined;
    this.prevIndex = prevIndex ? prevIndex : undefined;
    this.nextEntry = nextEntry ? nextEntry : undefined;
    this.nextIndex = nextIndex ? nextIndex : undefined;
  }
}
