import {
  BlogSummaryDto,
  BlogEntryDto,
  Message,
  SpiritMessage,
} from "../models";
import Stub from "./stub";
import { Api } from "./api";

const api = new Api();
export default class Proxy {
  private useMockData = process.env.VUE_APP_MOCKDATA === "true";

  public async wisdoms(): Promise<Message[]> {
    return Promise.resolve<Message[]>(
      this.useMockData ? await Stub.wisdoms() : await api.getWisdoms()
    );
  }
  public async angelMessage(): Promise<Message> {
    return Promise.resolve<Message>(
      this.useMockData ? await Stub.angelMessage() : await api.getAngelMessage()
    );
  }
  public async unicornMessage(): Promise<Message> {
    return Promise.resolve<Message>(
      this.useMockData
        ? await Stub.unicornMessage()
        : await api.getUnicornMessage()
    );
  }
  public async blogEntries(): Promise<BlogSummaryDto[]> {
    return Promise.resolve<BlogSummaryDto[]>(
      this.useMockData ? await Stub.blogEntries() : await api.getBlogEntries()
    );
  }
  public async blogEntry(guid: string): Promise<BlogEntryDto> {
    return Promise.resolve<BlogEntryDto>(
      this.useMockData ? await Stub.blogEntry() : await api.getBlogEntry(guid)
    );
  }
  async getChapters(talename: string): Promise<string[]> {
    return Promise.resolve<string[]>(
      this.useMockData
        ? await Stub.getChapters()
        : await api.getChapters(talename)
    );
  }
  async getChapter(talename: string, index: string): Promise<Message> {
    return Promise.resolve<Message>(
      this.useMockData
        ? await Stub.getChapter()
        : await api.getChapter(talename, index)
    );
  }
  async getSpiritMessages(category: string): Promise<SpiritMessage[]> {
    return Promise.resolve<SpiritMessage[]>(
      this.useMockData
        ? await Stub.spiritMessageEntries()
        : await api.getSpiritMessageEntries(category)
    );
  }
  async getSpiritMessage(category: string, id: string): Promise<Message> {
    return Promise.resolve<Message>(
      this.useMockData
        ? await Stub.spiritMessageEntry()
        : await api.getSpiritMessageEntry(category, id)
    );
  }
}
