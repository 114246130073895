import {
  BlogSummaryDto,
  BlogEntryDto,
  Message,
  SpiritMessage,
} from "../models";
import globalAxios from "axios";
interface iApi {
  getBlogEntries(): Promise<BlogSummaryDto[]>;
  getBlogEntry(guid: string): Promise<BlogEntryDto>;
}

export class Api implements iApi {
  async getWisdoms(): Promise<Message[]> {
    return new Promise((resolve, reject) => {
      globalAxios
        .get<Message[]>(process.env.VUE_APP_SITEURL + "/Site/wisdoms")
        .then((response) => {
          response.status === 200 || response.status === 204
            ? resolve(response.data)
            : reject(response.status);
        })
        .catch((ex) => {
          reject(ex);
        });
    });
  }
  async getAngelMessage(): Promise<Message> {
    return new Promise((resolve, reject) => {
      globalAxios
        .get<Message>(process.env.VUE_APP_SITEURL + "/Site/angelmessage")
        .then((response) => {
          response.status === 200 || response.status === 204
            ? resolve(response.data)
            : reject(response.status);
        })
        .catch((ex) => {
          reject(ex);
        });
    });
  }
  async getUnicornMessage(): Promise<Message> {
    return new Promise((resolve, reject) => {
      globalAxios
        .get<Message>(process.env.VUE_APP_SITEURL + "/Site/unicornmessage")
        .then((response) => {
          response.status === 200 || response.status === 204
            ? resolve(response.data)
            : reject(response.status);
        })
        .catch((ex) => {
          reject(ex);
        });
    });
  }
  async getBlogEntries(): Promise<BlogSummaryDto[]> {
    return new Promise((resolve, reject) => {
      globalAxios
        .get<BlogSummaryDto[]>(
          process.env.VUE_APP_SITEURL + "/Site/blogEntries"
        )
        .then((response) => {
          response.status === 200 || response.status === 204
            ? resolve(response.data)
            : reject(response.status);
        })
        .catch((ex) => {
          reject(ex);
        });
    });
  }
  async getBlogEntry(guid: string): Promise<BlogEntryDto> {
    return new Promise((resolve, reject) => {
      globalAxios
        .get<BlogEntryDto>(
          process.env.VUE_APP_SITEURL + "/Site/blogEntries/" + guid
        )
        .then((response) => {
          response.status === 200 || response.status === 204
            ? resolve(response.data)
            : reject(response.status);
        })
        .catch((ex) => {
          reject(ex);
        });
    });
  }
  async getChapters(talename: string): Promise<string[]> {
    return new Promise((resolve, reject) => {
      globalAxios
        .get<string[]>(
          process.env.VUE_APP_SITEURL + "/Site/taleChapters/" + talename
        )
        .then((response) => {
          response.status === 200 || response.status === 204
            ? resolve(response.data)
            : reject(response.status);
        })
        .catch((ex) => {
          reject(ex);
        });
    });
  }
  async getChapter(talename: string, index: string): Promise<Message> {
    return new Promise((resolve, reject) => {
      globalAxios
        .get<Message>(
          process.env.VUE_APP_SITEURL +
            "/Site/taleChapter/" +
            talename +
            "/" +
            index
        )
        .then((response) => {
          response.status === 200 || response.status === 204
            ? resolve(response.data)
            : reject(response.status);
        })
        .catch((ex) => {
          reject(ex);
        });
    });
  }
  async getSpiritMessageEntries(category: string): Promise<SpiritMessage[]> {
    return new Promise((resolve, reject) => {
      globalAxios
        .get<SpiritMessage[]>(
          process.env.VUE_APP_SITEURL + "/Site/spiritmessageEntries/" + category
        )
        .then((response) => {
          response.status === 200 || response.status === 204
            ? resolve(response.data)
            : reject(response.status);
        })
        .catch((ex) => {
          reject(ex);
        });
    });
  }
  async getSpiritMessageEntry(category: string, id: string): Promise<Message> {
    return new Promise((resolve, reject) => {
      globalAxios
        .get<Message>(
          process.env.VUE_APP_SITEURL +
            "/Site/spiritmessageEntry/" +
            category +
            "/" +
            id
        )
        .then((response) => {
          response.status === 200 || response.status === 204
            ? resolve(response.data)
            : reject(response.status);
        })
        .catch((ex) => {
          reject(ex);
        });
    });
  }
}
