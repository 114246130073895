export class SpiritMessage {
  public title = "";
  public lines: string[] = [""];
  public id = "0";

  constructor(title: string, lines: string[], id: string) {
    this.title = title;
    this.lines = lines;
    this.id = id;
  }
}
