import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import FrontPage from "../views/FrontPage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Etusivu",
    component: FrontPage,
  },
  {
    path: "/enkeliviesti",
    name: "Enkeliviesti",
    //component: AngelMessagePage,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "enkeliviesti" */ "../views/AngelMessagePage.vue"
      ),
  },
  {
    path: "/henkienviestit",
    name: "Henkien viestit",
    //component: SpiritMessagesPage,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "henkienviestit" */ "../views/SpiritMessagesPage.vue"
      ),
  },
  {
    path: "/yksisarvisviesti",
    name: "Viesti yksisarvisilta",
    //component: UnicornMessagePage,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "yksisarvisviesti" */ "../views/UnicornMessagePage.vue"
      ),
  },
  {
    path: "/kuulumisia",
    name: "Kuulumisia",
    component: () =>
      import(/* webpackChunkName: "kuulumisia" */ "../views/BlogPage.vue"),
  },
  {
    path: "/kuulumisia/:id",
    name: "Kuuluminen",
    props: true,
    component: () =>
      import(/* webpackChunkName: "kuuluminen" */ "../views/BlogEntryPage.vue"),
  },
  {
    path: "/satu/",
    name: "Tootoo",
    props: true,
    component: () =>
      import(/* webpackChunkName: "satu" */ "../views/TalesPage.vue"),
  },
  {
    path: "/kappale/:name/:index",
    name: "Kappale",
    props: true,
    component: () =>
      import(/* webpackChunkName: "satu" */ "../views/TalePage.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
      };
    }
    return { top: 0 };
  },
});

export default router;
